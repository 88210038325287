<template>
  <div class="event-snapshot">
    <div ref="zoom" class="zoom"
      @wheel="onWheel" @mousedown="onMouseDown" @mousemove="onMouseMove"
      @mouseup="onMouseUp">
      <img v-lazy="snapshotObj" ref="zoomImg" @load="onImgLoad" alt="">
      <div v-if="event.metadata" ref="plateBox" class="plate-box">
        <div v-if="enableZoomIn" class="zoom-in" @click="onShowZoomedImg">
          <img src="@/assets/icons/zoom-in-s.svg" alt="">
        </div>
      </div>
      <img v-if="event.channelId === 0" class="camera" src="@/assets/icons/camera1-lg.svg" alt="">
      <img v-else-if="event.channelId === 1" class="camera" src="@/assets/icons/camera2-lg.svg" alt="">
    </div>
    <div v-if="showZoomedImg" class="zoomed">
      <img ref="zoomedImg" v-lazy="photoObj" alt="">
      <div class="esc" @click="closeZoomedImg">
        <img src="@/assets/icons/clear.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'EventSnapshot',
  props: {
    event: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      default: 'lpr', // lpr / fr
    },
    enableZoomIn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      scale: 1.0,
      panning: false,
      pointX: 0,
      pointY: 0,
      start: { x: 0, y: 0 },
      divWidth: 0,
      divHeight: 0,
      imgWidth: 0,
      imgHeight: 0,
      paddingX: 0,
      paddingY: 0,
      defaultUnknow: require('@/assets/icons/unknow-user.svg'),
    }
  },
  computed: {
    ...mapState('history', ['isShowZoomPhoto']),
    showZoomedImg: {
      get() {
        return this.isShowZoomPhoto
      },
      set(val) {
        this.updateIsShowZoomPhoto(val)
      },
    },
    snapshotObj() {
      return {
        src: this.event.snapshotUrl,
        error: this.defaultUnknow,
      }
    },
    photoObj() {
      return {
        src: this.event.photoUrl,
        error: this.defaultUnknow,
      }
    },  
  },
  watch: {
    'event.snapshotUrl'() {
      if (this.$refs.plateBox)
        this.$refs.plateBox.style.opacity = 0
    },
    'event.id'() {
      this.resetZoomParams()
      this.plotDetectBox()
      this.showZoomedImg = false
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    ...mapMutations('history', ['updateIsShowZoomPhoto']),
    init() {
      // 計算影像zoomImg維持原長寬比例，顯示於zoom中，x,y 方向的padding
      this.divWidth = this.$refs.zoom.offsetWidth
      this.divHeight = this.$refs.zoom.offsetHeight
      let imgNaturalWidth = this.$refs.zoomImg.naturalWidth
      let imgNaturalHeight = this.$refs.zoomImg.naturalHeight
      let fitScale = Math.min(this.divWidth / imgNaturalWidth, this.divHeight / imgNaturalHeight)
      this.imgWidth = imgNaturalWidth * fitScale
      this.imgHeight = imgNaturalHeight * fitScale
      this.paddingX = (this.divWidth - this.imgWidth) / 2
      this.paddingY = (this.divHeight - this.imgHeight) / 2
    },
    resetZoomParams() {
      this.pointX = 0
      this.pointY = 0
      this.start = { x: 0, y: 0 }
      this.scale = 1.0
      this.$refs.zoom.style.transform = `scale(${this.scale})`
      this.init()
    },
    correctTranslatePoint() {
      let paddingLeft = -this.paddingX * this.scale
      let paddingRight = -(this.imgWidth * this.scale - paddingLeft - this.divWidth)
      let paddingTop = -this.paddingY * this.scale
      let paddingBottom = -(this.imgHeight * this.scale - paddingTop - this.divHeight)

      if (this.pointX > paddingLeft) this.pointX = paddingLeft
      if (this.pointX < paddingRight) this.pointX = paddingRight
      if (this.pointY > paddingTop) this.pointY = paddingTop
      if (this.pointY < paddingBottom) this.pointY = paddingBottom
    },
    setTransform() {
      this.$refs.zoom.style.transform = `translate(${this.pointX}px, ${this.pointY}px) scale(${this.scale})`
    },
    onWheel(e) {
      e.preventDefault()
      let xs = (e.clientX - this.pointX) / this.scale
      let ys = (e.clientY - this.pointY) / this.scale
      let delta = (e.wheelDelta ? e.wheelDelta : -e.deltaY)
      if (delta > 0) { // 放大
        this.scale *= 1.05
      } else { // 縮小，最小倍率為1.0
        let tmpScale = this.scale / 1.05
        this.scale = tmpScale < 1.0 ? 1.0 : tmpScale
      }

      this.pointX = e.clientX - xs * this.scale
      this.pointY = e.clientY - ys * this.scale

      if (delta <= 0) this.correctTranslatePoint()
      if (this.scale == 1.0) this.resetZoomParams()

      this.setTransform()
    },
    onMouseDown(e) {
      e.preventDefault()
      this.start = { x: e.clientX - this.pointX, y: e.clientY - this.pointY }
      this.panning = true
    },
    onMouseMove(e) {
      e.preventDefault()
      if (!this.panning || this.scale == 1.0) return
      this.pointX = e.clientX - this.start.x
      this.pointY = e.clientY - this.start.y
      this.correctTranslatePoint()
      this.setTransform()
    },
    onMouseUp() {
      this.panning = false
    },
    handleResize() {
      this.onImgLoad()
    },
    plotDetectBox() {
      if (!this.event.metadata) return // sos event

      const x = this.mode === 'fr' ? this.event.metadata[0].x : this.event.metadata[0].licenseplatePos.x
      const y = this.mode === 'fr' ? this.event.metadata[0].y : this.event.metadata[0].licenseplatePos.y
      const w = this.mode === 'fr' ? this.event.metadata[0].w : this.event.metadata[0].licenseplatePos.w
      const h = this.mode === 'fr' ? this.event.metadata[0].h : this.event.metadata[0].licenseplatePos.h
      
      if (w === 0 && h === 0) {
        this.$refs.plateBox.style.opacity = 0
        return
      }
      this.$refs.plateBox.style.opacity = 1
      this.$refs.plateBox.style.left = `${x * this.imgWidth + this.paddingX}px`
      this.$refs.plateBox.style.top = `${y * this.imgHeight + this.paddingY}px`
      this.$refs.plateBox.style.width = `${w * this.imgWidth}px`
      this.$refs.plateBox.style.height = `${h * this.imgHeight}px`
      this.$refs.plateBox.style.transform = `scale(${this.scale})`
    },
    onImgLoad() {
      this.resetZoomParams()
      this.plotDetectBox()
    },
    onShowZoomedImg() {
      this.showZoomedImg = true
    },
    closeZoomedImg() {
      this.showZoomedImg = false
    },
  }, 
}
</script>

<style lang="scss" scoped>
.event-snapshot {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 1px solid #4A5C78;
  .zoom {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    transform-origin: 0px 0px;
    transform: scale(1) translate(0px, 0px);
    cursor: grab;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
    img[lazy=error] {
      width: 60%;
      height: 60%;
      & + .plate-box {
        display: none;
      }
    }
    img[lazy=loading] + .plate-box {
      display: none;
    }
    & > .camera {
      position: absolute;
      left: 0px;
      bottom: 0px;
      width: 64px;
      height: 64px;
    }
  }
  .plate-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border: 2px solid #F94144;
    background-color: transparent;
    .zoom-in {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(70%, -70%);
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: #F94144;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .zoomed {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #191919cc;
    overflow: hidden;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    & > img {
      height: 90%;
      object-fit: contain;
    }
    .esc {
      position: absolute;
      right: 0;
      top: 0; 
      display: flex;
      justify-content: center;
      align-items: center;
      width: 62px;
      height: 62px;
      border-radius: 50%;
      cursor: pointer;
      &:hover {
        background: #19191980;
      }
    }
  }
}
</style>