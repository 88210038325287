<template>
  <div class="event-info-fr">
    <div class="info-items">
      <div v-if="isMatch" class="person">
        <div class="icon">
          <img :src="getGenderIcon(selectedEvent.gender)" alt="">
        </div>
        <div class="name">{{ selectedEvent.name }}</div>
      </div>
      <div v-else class="person">
        <div class="icon">
          <img src="@/assets/icons/user-yellow-L.svg" alt="">
        </div>
        <div class="name">{{ $t('unknown') }}</div>
      </div>
      <div class="time">
        <span>{{ detectTime }}</span>
      </div>
      <div class="info">
        <TooltipElement :content="$t('history_fr_id')" alwaysShow>
          <img src="@/assets/icons/id-card.svg" alt="">
        </TooltipElement>
        <span v-if="isMatch">{{ selectedEvent.idCard }}</span>
      </div>
      <div class="info">
        <TooltipElement :content="$t('history_fr_age')" alwaysShow>
          <img src="@/assets/icons/person-age.svg" alt="">
        </TooltipElement>
        <span v-if="isMatch && selectedEvent.birthday">{{ getAge(selectedEvent.birthday) }}</span>
        <span v-if="isMatch && selectedEvent.birthday" class="small">({{ birthday }})</span>
      </div>
      <div class="info tags">
        <TooltipElement :content="$t('history_fr_tag'/*人物標記*/)" alwaysShow>
          <img src="@/assets/icons/tag.svg" alt="">
        </TooltipElement>
        <div v-if="isMatch" class="wrap-tags">
          <span v-for="(tag, index) in getTagNameList(selectedEvent.tag, frTagList)" :key="index">{{ tag }}</span>
        </div>
      </div>
        
      <div class="info push-content">
        <TooltipElement :content="$t('history_fr_remark'/*備註*/)" alwaysShow>
          <img src="@/assets/icons/note.svg" alt="">
        </TooltipElement >
        <span>{{ event.description }}</span>
      </div>
      <div class="info group">
        <TooltipElement :content="$t('history_fr_group'/*群組*/)" alwaysShow>
          <img src="@/assets/icons/Group.svg" alt="">
        </TooltipElement>
        <span>{{ getGroupName(event.groupId) }}</span>
      </div>
      <div class="info video-title">
        <TooltipElement :content="$t('history_fr_device'/*設備*/)" alwaysShow>
          <img src="@/assets/icons/Camera.svg" alt="">
        </TooltipElement>
        <span>{{ getEventTitleId(event.user.id) }}</span>
      </div>
      <div class="info gps" @click="goGoogleMap(event.latitude, event.longitude)">
        <TooltipElement :content="$t('history_fr_gps'/*GPS位置*/)" alwaysShow>
          <img src="@/assets/icons/gps.svg" alt="">
        </TooltipElement>
        <span v-if="event.latitude && event.longitude">{{ event.latitude }}, {{ event.longitude }}</span>
        <TooltipElement :content="$t('history_fr_open_google_map'/*開啟Google map*/)" alwaysShow>
          <img class="map-icon" src="@/assets/icons/google-map-icon.png" alt="">
        </TooltipElement>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'
import { 
  getGenderIcon,
  getTagNameStr,
  getTagNameList,
  goGoogleMap,
} from '@/utils/index.js'
import { getAge } from '@/utils/lib.js'

export default {
  name: 'EventInfoFr',
  components: {
    TooltipElement: () => import('@/components/Base/TooltipElement.vue')
  },
  props: {
    event: {
      type: Object
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState(['frTagList', 'groupList']),
    ...mapState('historyFr', ['rank']),
    ...mapGetters(['getEventTitleId', 'timezone']),
    selectedEvent() {
      return this.event.triggered.find(item => item.rank === this.rank)
    },
    isMatch() {
      return this.event.triggered.length > 0 && this.selectedEvent
    },
    detectTime() {
      return moment(this.event.detectTime).tz(this.timezone).format(
        'YYYY-MM-DD HH:mm:ss'
      )
    },
    birthday() {
      return moment(this.selectedEvent.birthday).tz(this.timezone).format(
        'YYYY-MM-DD'
      )
    },
  },
  methods: {
    getAge,
    getGenderIcon,
    getTagNameStr,
    getTagNameList,
    goGoogleMap,
    getGroupName(groupId) {
      const group = this.groupList.find((item) => item.id === groupId)
      return group ? group.name : ''
    },
  }
}
</script>

<style lang="scss" scoped>
.event-info-fr {
  width: 100%;
  padding-right: 20px;
  display: flex;
  column-gap: 32px;
  box-sizing: border-box;

  .info-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    font-size: px2rem(20);
    line-height: 36px;
  }

  span {
    font-size: px2rem(20);
    line-height: 36px;
    color: #ffffff;
  }

  .person {
    position: relative;
    display: flex;
    align-items: center;
    .icon {
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }
    .name {
      font-family: Roboto;
      font-size: px2rem(24);
      font-weight: 400;
      line-height: 38px;
      color: #FFE99F;
    }
  }

  .time span {
    display: inline-block;
    background: #4A5C78;
    border-radius: 15px;
    padding: 2px 12px 3px;
    font-size: px2rem(18);
    line-height: 26px;
  }

  .info {
    width: 100%;
    display: flex;
    align-items: flex-start;
    position: relative;
    img {
      width: 24px;
      height: 24px;
      margin-right: 12px;
      margin-top: 8px;
    }
    .small {
      font-size: px2rem(16);
      line-height: 20px;
      color: #FFFFFF80;
      margin-left: 4px;
      margin-top: 8px;
    }
  }

  .info img {
    width: 24px;
    height: 24px;
  }

  .gps {
    color: #ffffff;
      
    img img {
      margin-left: 20px;
      cursor: pointer;
    }
    span {
      margin-right: 20px;
    }
    .map-icon {
      cursor: pointer;
    }
  }

  .note span {
    width: 100%;
    font-weight: 300;
  }

  .separater {
    width: 100%;
    height: 2px;
    border-top: 1px solid #ffffff33;
  }

  .message {
    display: flex;
    align-items: center;
  }

  .push-content, .group, .video-title { 
    span {
      font-size: px2rem(20);
    }
  }

  .group { 
    span {
      font-weight: 400;
      color: #FFC600;
    }
  }

  .video-title {
    width: 100%;
    display: flex;
    span {
      font-size: px2rem(20);
    }
  }
}
.tags {
  width: 100%;
  .wrap-tags {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
  }
  span {
    background: #ffffff33;
    font-size: px2rem(20);
    line-height: 35px;
    padding: 0 12px;
    margin-right: 8px;
    border-radius: 18px;
    white-space: nowrap;
  }
}
</style>