<template>
  <div class="fr-event-photo" :style="imgStyle">
    <div class="photo">
      <img v-lazy="photoObj">
    </div>
    <div class="photo">
      <img v-lazy="featurePhotoObj">
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'FrEventPhoto',
  props: {
    event: {
      type: Object,
      required: true
    },
    zoomFactor: {
      type: Number,
      default: 1.0
    }
  },
  data() {
    return {
      defaultUnknow: require('@/assets/icons/unknow-user.svg')
    }
  },
  computed: {
    ...mapState('historyFr', ['rank']),
    selectedEvent() {
      return this.event.triggered.find(item => item.rank === this.rank)
    },
    imgStyle() {
      return {
        '--imgSize': `${190 * this.zoomFactor}px`
      }
    },
    photoObj() {
      return {
        src: this.event.photoUrl,
        error: this.defaultUnknow,
      }
    },
    featurePhotoObj() {
      return {
        src: this.selectedEvent.featurePhotoUrl,
        error: this.defaultUnknow,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.fr-event-photo {
  width: 100%;
  display: flex;
  column-gap: 12px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ffffff80;

  .photo {
    width: var(--imgSize);
    height: var(--imgSize);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF1a;
    border-radius: 8px;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    img[lazy=error] {
      width: 60%;
      height: 60%;
    }
  }
}
</style>