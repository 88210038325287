<template>
  <div class="fr-event-result" :style="imgStyle">
    <img v-if="hasArrow" class="arrow" src="@/assets/icons/ArrowLeft-sq.svg" @click="switchIndex(-1)" alt="">
    <div ref="containerFrResult" class="container-result">
      <div v-for="item in sortRankTriggered" :key="item.rank"
        ref="resultFrItem" 
        class="result-item" 
        :class="{ selected: item.rank === selectedRank }"
        @click="selectCandidate(item.rank)">
        <div class="photo">
          <img v-lazy="{ src: item.featurePhotoUrl, error: defaultUnknow }" alt="">
        </div>
        <div class="info">
          <TooltipElement :content="item.name" placement="top">
            <div class="name">{{ item.name }}</div>
          </TooltipElement>
          <div class="score">{{ getScore(item.score) }}%</div>
        </div>
      </div>
    </div>
    <img v-if="hasArrow" class="arrow rotate" src="@/assets/icons/ArrowLeft-sq.svg" @click="switchIndex(1)" alt="">
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'FrEventMatch',
  components: {
    TooltipElement: () => import('@/components/Base/TooltipElement.vue')
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    zoomFactor: {
      type: Number,
      default: 1.0
    },
    showArrow: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      minRank: 0,
      defaultUnknow: require('@/assets/icons/unknow-user.svg')
    }
  },
  computed: {
    ...mapState('historyFr', ['rank']),
    imgStyle() {
      return {
        '--imgSize': `${110 * this.zoomFactor}px`
      }
    },
    selectedRank: {
      get() {
        return this.rank
      },
      set(val) {
        this.updateRank(val)
      }
    },
    sortRankTriggered() {
      const temp = [...this.event.triggered]
      return temp.sort((a, b) => a.rank - b.rank)
    },
    hasArrow() {
      if (this.$refs.containerFrResult === undefined) return false
      const containerWidth = this.$refs.containerFrResult.offsetWidth
      const contentWidth = this.$refs.containerFrResult.scrollWidth
      return this.showArrow && containerWidth < contentWidth
    }
  },
  watch: {
    'event.id'() {
      this.updateMinRank()
    },
    selectedRank() {
      this.moveToCenter()
    }
  },
  mounted() {
    this.updateMinRank()
  },
  methods: {
    ...mapMutations('historyFr', ['updateRank']),
    updateMinRank() {
      const rankList = this.sortRankTriggered.map(item => item.rank)
      this.minRank = rankList.length ? Math.min(...rankList) : 0
      this.updateRank(this.minRank)
    },
    getScore(score) {
      return Math.round(score)  // 四捨五入取整數
    },
    selectCandidate(rank) {
      this.updateRank(rank)
    },
    handleArrow() {
      const containerWidth = this.$refs.containerFrResult.offsetWidth
      const contentWidth = this.$refs.containerFrResult.scrollWidth
      this.hasArrow = this.showArrow && containerWidth < contentWidth
    },
    moveToCenter() {
      const frIdx = this.selectedRank - this.minRank
      this.$refs.resultFrItem[frIdx].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      })
    },
    switchIndex(step) {
      let newIndex = this.selectedRank + step
      if (newIndex < 0 || newIndex > 2) return
      this.updateRank(newIndex)
    },
  }
}
</script>

<style lang="scss" scoped>
.fr-event-result {
  width: 100%;
  display: flex;
  column-gap: 8px;

  .container-result {
    flex: 1;
    display: flex;
    column-gap: 8px;
    overflow: hidden;
  }

  .result-item {
    display: flex;
    border-radius: 8px;
    box-sizing: border-box;
    position: relative;
    opacity: 0.5;
    cursor: pointer;

    &.selected {
      opacity: 1;
    }

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 8px;
      border: 4px solid #FFC600;
      opacity: 0;
    }
    &.selected::before {
      opacity: 1;
    }
    
    .photo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: var(--imgSize);
      height: var(--imgSize);
      border-radius: 8px 0 0 8px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      img[lazy=error] {
        width: 70%;
        height: 70%;
      }
    }

    .info {
      min-width: var(--imgSize);
      height: var(--imgSize);
      background: #4A5C78;
      padding: 10px 8px;
      border-radius: 0 8px 8px 0;
      color: #ffffff;
      font-size: 24px;
      line-height: 36px;
      box-sizing: border-box;

      .name {
        max-width: 180px;
        margin-bottom: 6px;
        font-size: 24px;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
      .score {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}

.arrow {
  &:hover {
    background: #ffffff16;
  }
  cursor: pointer;
}

.rotate {
  transform: rotate(180deg);
}
</style>